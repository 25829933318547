<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useInbound } from '@/store/inbound';
import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import NoData from '@/components/NoData/NoData.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import { useAuth } from '@/store/auth';
import { Roles } from 'types/roles';

const router = useRouter();
const { addToast } = useMessages();

const inboundStore = useInbound();
await inboundStore.getAll();

const authStore = useAuth();
const userRole = computed(() => authStore.user.role);

const inboundList = computed(() => inboundStore.listAll);

const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  name: '',
});

const onSwitchStatus = async (id: string, active: boolean) => {
  await inboundStore.update(id, { active });
  addToast({
    type: MessageType.Success,
    content: 'Kalender ändern erfolgreich',
  });
  inboundStore.getAll();
};

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = data;
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  await inboundStore.delete(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  inboundStore.getAll();
};

const loading = ref(false);
const refreshListData = async () => {
  loading.value = true;
  await inboundStore.getAll();
  loading.value = false;
};

const typeToText = (type: string) => {
  switch (type) {
    case 'REMOTE':
      return 'Videoberatung';
    case 'LOCAL':
      return 'Vor-Ort Termin';
    case 'GROUP':
      return 'Kalender-Gruppe';
  }
};
</script>
<template>
  <div class="page-content w-full">
    <PageHeading
      title="Kalender"
      :button="true"
      to="/admin/inbound/add"
      :refresh="true"
      :loading="loading"
      @click-refresh="refreshListData"
    />
    <div class="card">
      <DataTable :value="inboundList" responsive-layout="scroll" class="custom-table" :loading="loading">
        <Column
          v-if="Roles[userRole] === Roles.superAdmin"
          field="type"
          header="Tenant"
          :sortable="true"
          sort-field="tenant.name"
        >
          <template #body="slotProps">
            <span :class="{ 'text-light-grey': !slotProps.data.active }">{{ slotProps.data.tenant?.name ?? '-' }}</span>
          </template>
        </Column>
        <Column field="type" header="Art" :sortable="true" sort-field="type">
          <template #body="slotProps">
            <span :class="{ 'text-light-grey': !slotProps.data.active }">{{ typeToText(slotProps.data.type) }}</span>
          </template>
        </Column>
        <Column field="name" header="Name" :sortable="true" sort-field="name">
          <template #body="slotProps">
            <span :class="{ 'text-light-grey': !slotProps.data.active }">{{ slotProps.data.name }}</span>
          </template>
        </Column>
        <Column field="active" header="Aktiv" :sortable="true" sort-field="active">
          <template #body="slotProps">
            <InputSwitch
              v-model="slotProps.data.active"
              :class="{ disabled: !slotProps.data.active }"
              @click="onSwitchStatus(slotProps.data.id, !slotProps.data.active)"
            />
          </template>
        </Column>
        <Column field="active" header>
          <template #body="slotProps">
            <i
              class="pi pi-cog text-l cursor-pointer hover:text-first p-2 mr-2"
              :slotProps="slotProps"
              @click="router.push({ path: `/admin/inbound/${slotProps.data.id}/edit` })"
            ></i>
            <i
              class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2"
              :slotProps="slotProps"
              @click="onClickDelete({ id: slotProps.data.id, name: slotProps.data.name })"
            ></i>
          </template>
        </Column>
        <template #empty>
          <div class="flex items-center justify-center py-2 md:py-10">
            <NoData
              :title="`Keine Kalender!`"
              :loading="loading"
              description="Legen Sie bitte oben einen neuen Kanal an."
            />
          </div>
        </template>
      </DataTable>
    </div>
    <teleport to="body">
      <BaseModal
        :data-modal="dataModalDelete"
        :open="openModal"
        @update:open="openModal = $event"
        @submit="onHandleDelete"
      >
        <template #body="{ slotProps }">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie den Kanal
              <strong>{{ slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.p-datatable .p-sortable-column:focus {
  @apply shadow !shadow-xs;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  @apply !text-first;
}
.p-datatable-table .p-datatable-tbody:before {
  content: '-';
  @apply leading-6	text-transparent block;
}
.p-inputswitch .p-inputswitch-slider {
  @apply !bg-first;
}
.p-inputswitch.disabled .p-inputswitch-slider {
  @apply !bg-light-grey;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  @apply shadow-none;
}
</style>
