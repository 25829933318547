// store/todos.js
import { Message } from "types/message";
import { reactive, toRefs } from "vue";
import { useToast } from "primevue/usetoast";

const config = Object.freeze({
  timeout: 3000,
});

const state = reactive({
  messages: <Message[]>[],
  toasts: <Message[]>[],
});

export default function useMessages() {
  const toast = useToast();

  const addMessage = (message: Message) => {
    if (!message.displayTime) {
      message.displayTime = config.timeout;
    }
    state.messages.push(message);
    setTimeout(() => {
      state.messages.shift();
    }, config.timeout);
  };
  const addToast = (toastMessage: Message) => {
    if (!toastMessage.displayTime) {
      toastMessage.displayTime = config.timeout;
    }
    state.toasts.push(toastMessage);
    toast.add({
      severity: toastMessage.type,
      summary: toastMessage.content,
      detail: toastMessage.content,
      life: toastMessage.displayTime,
    });
    setTimeout(() => {
      state.toasts.shift();
    }, config.timeout);
  };

  return {
    ...toRefs(state), // convert to refs when returning
    addMessage,
    addToast,
  };
}
